"use client";
import { LoginForm } from "@/components/organisms/login-form";
import { useAuth } from "@aether/shared/context/auth";
import { IdCardIcon } from "@radix-ui/react-icons";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function LoginPage() {
  const router = useRouter();

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      router.replace("/search");
    }
  }, [user, router]);

  return (
    <main className="container flex min-h-screen flex-col items-center justify-between p-10">
      <div className="max-w-md w-full rounded-lg border">
        <span className="flex items-center border-b px-3 py-1">
          <IdCardIcon className="h-12 w-12" />
          <h1 className="text-2xl p-2 font-semibold text-center">
            Login to AetherLenz
          </h1>
        </span>
        <LoginForm />
      </div>
    </main>
  );
}
